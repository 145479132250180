<template>
    <DxToolbar :height="56" class="toolbar">
        <DxItem location="before" locate-in-menu="never">
            <template #default>
                <routerVue :breadcrumbsItems="breadcrumbsItems" />
            </template>
        </DxItem>
        <DxItem location="after" locate-in-menu="never">
            <template #default>
                <div>
                    <DxButton
                        key="mdi-HUY"
                        type="default"
                        text="HỦY"
                        styling-mode="text"
                        :width="100"
                        class="mr-4"
                        @click="
                            $router.push('/Loai-Hoa-Don-Doanh-Nghiep-Su-Dung')
                        "
                    /><DxButton
                        key="mdi-XACNHAN"
                        type="default"
                        text="LƯU"
                        :width="100"
                        styling-mode="contained"
                        @click="CapNhat()"
                    />
                </div>
            </template>
        </DxItem>
    </DxToolbar>

    <div class="row justify-center mt-4">
        <DxValidationGroup ref="formValidation">
            <div class="frame-box">
                <div class="row font-20 font-medium justify-center">
                    Cập nhật loại hóa đơn
                </div>
                <div class="row mt-3">
                    <div class="xs12">
                        <DxTextBox
                            v-model="$Core.Auth.TokenParsed.TenDoanhNghiep"
                            label="Tên doanh nghiệp (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                            :readOnly="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Tên doanh nghiệp không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="xs12">
                        <DxSelectBox
                            v-model="DuLieuCapNhatLoaiHoaDon.guidLoaiHoaDon"
                            label="Loại hóa đơn (*)"
                            :data-source="DanhSachLoaiHoaDon"
                            display-expr="tenLoaiHoaDon"
                            value-expr="guidLoaiHoaDon"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                            :search-enabled="true"
                            :onItemClick="LoaiHoaDon"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Loại hóa đơn không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="xs12">
                        <DxTextBox
                            v-model="DuLieuCapNhatLoaiHoaDon.maSoThue"
                            label="Mã số thuế (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Mã số thuế không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="xs6 mr-2">
                        <DxTextBox
                            v-model="DuLieuCapNhatLoaiHoaDon.domain"
                            label="Tên miền (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Tên miền không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                    <div class="xs6 ml-3">
                        <DxTextBox
                            v-model="DuLieuCapNhatLoaiHoaDon.linkTraCuu"
                            label="link Tra cứu (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Link tra cứu không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="xs6 mr-2">
                        <DxTextBox
                            v-model="DuLieuCapNhatLoaiHoaDon.taiKhoan"
                            label="Tài khoản (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Tài khoản không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                    <div class="xs6 ml-3">
                        <DxTextBox
                            v-model="DuLieuCapNhatLoaiHoaDon.matKhau"
                            label="Mật khẩu (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Mật khẩu không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="xs6 mr-2">
                        <DxTextBox
                            v-model="DuLieuCapNhatLoaiHoaDon.taiKhoanSv"
                            label="Tài khoản Service (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Tài khoản Service không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                    <div class="xs6 ml-3">
                        <DxTextBox
                            v-model="DuLieuCapNhatLoaiHoaDon.matKhauSv"
                            label="Mật khẩu Service (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Mật khẩu Service không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="xs12">
                        <DxTextBox
                            v-model="DuLieuCapNhatLoaiHoaDon.appId"
                            label="App ID (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="App ID không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="xs12">
                        <DxTextBox
                            v-model="DuLieuCapNhatLoaiHoaDon.DiaChi"
                            label="Địa chỉ (*)"
                            labelMode="floating"
                            styling-mode="outlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Địa chỉ không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
            </div>
        </DxValidationGroup>
    </div>

    <!-- <DataTableVue /> -->
</template>

<script>
import {
    DxDataGrid,
    DxPaging,
    DxScrolling,
    DxColumn,
    DxEditing,
} from "devextreme-vue/data-grid";
import { v4 as uuidv4 } from "uuid";
import { DxToolbar } from "devextreme-vue";
import routerVue from "@sonphat/common-v1/components/router";
// import DataTableVue from "./components/DataTable.vue";
import { DxItem } from "devextreme-vue/toolbar";
import {
    DxTextBox,
    DxSelectBox,
    DxValidator,
    DxValidationGroup,
    DxDateBox,
    DxButton,
} from "devextreme-vue";
import { DxPatternRule, DxRangeRule } from "devextreme-vue/validator";
import { DxRequiredRule } from "devextreme-vue/data-grid";
import { mapState } from "vuex";
export default {
    components: {
        DxDataGrid,
        DxEditing,
        DxPaging,
        DxScrolling,
        DxColumn,
        routerVue,
        DxToolbar,
        DxItem,

        DxPatternRule,

        DxRangeRule,
        DxRequiredRule,

        DxTextBox,
        DxSelectBox,
        DxValidator,
        DxValidationGroup,
        DxDateBox,
        DxButton,
    },
    data() {
        return {
            MaxDate: new Date(this.$i18n.t("DefaultNumBer.MaxDate")),
            MinDate: new Date(this.$i18n.t("DefaultNumBer.MinDate")),
            DuLieuCapNhatLoaiHoaDon: {
                guidCauHinh: "",
                guidLoaiHoaDon: "",
                maSoThue: "",
                domain: "",
                linkTraCuu: "",
                taiKhoan: "",
                taiKhoanSv:"",
                matKhau: "",
                matKhauSv: "",
                appId: "",
                DiaChi: "",
            },

            breadcrumbsItems: [
                {
                    id: this.$t("namePath.KeHoachHoatDong"),
                    text: "Danh sách hóa đơn doanh nghiệp sử dụng",
                    disabled: false,
                    to: "/Loai-Hoa-Don-Doanh-Nghiep-Su-Dung",
                },
                {
                    id: this.$t("namePath.QuanLyTuyenVanChuyen"),
                    text: "Cập nhật loại hóa đơn",
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    created() {
        let query = this.$route.query;
        if (query && query.guidLoaiHoaDon) {
            this.LayDanhSachLoaiHoaDonDoanhNghiepSuDung();
        }
        this.LayDanhSachLoaiHoaDon();
    },
    mounted() {},
    computed: {
        ...mapState({
            DanhSachLoaiHoaDon: (state) =>
                state.CongHoaDonDienTu.DanhSachLoaiHoaDon,
        }),
    },
    methods: {
        ThongBao(type, message) {
            let modelToast = {
                isVisible: true,
                message: message,
                type: type,
            };
            this.emitter.emit("onToast", modelToast);
        },
        async LayDanhSachLoaiHoaDonDoanhNghiepSuDung() {
            try {
                this.$startLoading;
                let rs = await this.$store.dispatch(
                    "CongHoaDonDienTu/LayDanhSachLoaiHoaDonDoanhNghiepSuDung",
                    {
                        guidDoanhNghiep:
                            this.$Core.Auth.TokenParsed.GuidDoanhNghiep,
                    }
                );
                this.$stopLoading;
                if (!rs.status) {
                    this.ThongBao(
                        "error",
                        `Lấy danh sách loại hóa đơn doanh nghiệp sử dụng thất bại! ${rs.message}`
                    );
                } else {
                    console.log("rs.data", rs.data);
                    rs.data.forEach((e) => {
                        if (
                            e.guidLoaiHoaDon == this.$route.query.guidLoaiHoaDon
                        ) {
                            this.DuLieuCapNhatLoaiHoaDon.guidCauHinh =
                                e.guidKyHieu;
                            this.DuLieuCapNhatLoaiHoaDon.guidLoaiHoaDon =
                                e.guidLoaiHoaDon;
                            this.DuLieuCapNhatLoaiHoaDon.maSoThue = e.maSoThue;
                            this.DuLieuCapNhatLoaiHoaDon.domain = e.domain;
                            this.DuLieuCapNhatLoaiHoaDon.linkTraCuu =
                                e.linkTraCuu;
                            this.DuLieuCapNhatLoaiHoaDon.taiKhoan = e.taiKhoan;
                            this.DuLieuCapNhatLoaiHoaDon.matKhau = e.matKhau;
                            this.DuLieuCapNhatLoaiHoaDon.taiKhoanSv = e.taiKhoanService;
                            this.DuLieuCapNhatLoaiHoaDon.matKhauSv = e.matKhauService;
                            this.DuLieuCapNhatLoaiHoaDon.appId = e.appID;
                            this.DuLieuCapNhatLoaiHoaDon.DiaChi = e.diaChi;
                        }
                    });
                }
            } catch (error) {
                console.log(error, "error");
                // this.ThongBao(
                //     "error",
                //     `Lấy danh sách loại hóa đơn doanh nghiệp sử dụng  thất bại! Lỗi hệ thống`
                // );
                this.$stopLoading;
            }
        },

        async LayDanhSachLoaiHoaDon() {
            try {
                this.$startLoading;
                let rs = await this.$store.dispatch(
                    "CongHoaDonDienTu/LayDanhSachLoaiHoaDon"
                );
                this.$stopLoading;
                if (!rs.status) {
                    this.ThongBao(
                        "error",
                        `Lấy danh sách loại hóa đơn thất bại! ${rs.message}`
                    );
                } else {
                }
            } catch (error) {
                this.ThongBao(
                    "error",
                    `Lấy danh sách loại hóa đơn thất bại! Lỗi hệ thống`
                );
                this.$stopLoading;
            }
        },

        async CapNhatLoaiHoaDon() {
            try {
                this.$startLoading;
                let DuLieuGuiDi = {
                    guidCauHinh:
                        this.DuLieuCapNhatLoaiHoaDon.guidCauHinh == ""
                            ? uuidv4()
                            : this.DuLieuCapNhatLoaiHoaDon.guidCauHinh,
                    guidDoanhNghiep:
                        this.$Core.Auth.TokenParsed.GuidDoanhNghiep,
                    guidLoaiHoaDon: this.DuLieuCapNhatLoaiHoaDon.guidLoaiHoaDon,
                    maSoThue: this.DuLieuCapNhatLoaiHoaDon.maSoThue,
                    domain: this.DuLieuCapNhatLoaiHoaDon.domain,
                    linkTraCuu: this.DuLieuCapNhatLoaiHoaDon.linkTraCuu,
                    taiKhoan: this.DuLieuCapNhatLoaiHoaDon.taiKhoan,
                    matKhau: this.DuLieuCapNhatLoaiHoaDon.matKhau,
                    appId: this.DuLieuCapNhatLoaiHoaDon.appId,
                    diaChi: this.DuLieuCapNhatLoaiHoaDon.DiaChi,
                    matKhauService: this.DuLieuCapNhatLoaiHoaDon.matKhauSv,
                    taiKhoanService: this.DuLieuCapNhatLoaiHoaDon.taiKhoanSv,
                };
                let rs = await this.$store.dispatch(
                    "CongHoaDonDienTu/CapNhatLoaiHoaDon",
                    DuLieuGuiDi
                );
                this.$stopLoading;
                if (!rs.status) {
                    this.ThongBao(
                        "error",
                        `Cập nhật loại hóa đơn thất bại thất bại ${rs.message}`
                    );
                } else {
                    this.ThongBao(
                        "success",
                        `Cập nhật loại hóa đơn thành công!`
                    );
                    this.$router.push("/Loai-Hoa-Don-Doanh-Nghiep-Su-Dung");
                }
            } catch (error) {
                console.log(error);
                this.$stopLoading;
            }
        },

        validate() {
            return this.$refs.formValidation.instance.validate();
        },
        CapNhat() {
            if (!this.validate().isValid) {
                this.$Helper.Common.autoFocusError(this.validate());
            } else {
                this.CapNhatLoaiHoaDon();
            }
        },
    },
    watch: {},
};
</script>

<style scoped>
.title {
    font-weight: 500;
    font-size: 16px;
}
.frame-box {
    width: 554px;
    border: 1px solid #dadce0;
    padding: 24px;
}
.shrink {
    display: flex;
    justify-content: flex-end;
}
</style>
